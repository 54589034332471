<template>
  <!-- eslint-disable max-len -->
  <section id="promotions" class="Promotions">
    <div class="Title Title--type-h2 Promotions-Title">
      {{ $t('homepage.promotions.title') }}<br/>
      {{ $t('prepositions.in') }} <span class="Colored">{{ $t('title') }}</span>
    </div>
    <div class="Promotions-Slider">
      <VueSlider v-bind="options">
        <PromotionsCards class="Promotions-Row" v-for="(row, i) in promotions" :key="i" :promotions="localizedPromotions[i]"/>
      </VueSlider>
    </div>
  </section>
</template>

<script>
import VueSlider from '@/components/Slider.vue';
import PromotionsCards from '@/components/PromotionsCards.vue';

export default {
  name: 'Promotions',
  components: {
    VueSlider,
    PromotionsCards,
  },
  data() {
    return {
      options: {
        items: 1,
        loop: true,
        margin: 10,
        nav: true,
        dots: true,
      },
      promotions: [
        [
          {
            image: 'welcome.svg',
            title: 'homepage.promotions.items.promo1.title',
            text: 'homepage.promotions.items.promo1.text',
          },
          {
            image: 'tournaments.svg',
            title: 'homepage.promotions.items.promo2.title',
            text: 'homepage.promotions.items.promo2.text',
          },
          {
            image: 'vip.svg',
            title: 'homepage.promotions.items.promo3.title',
            text: 'homepage.promotions.items.promo3.text',
          },
        ],
        [
          {
            image: 'daily-picks.svg',
            title: 'homepage.promotions.items.promo4.title',
            text: 'homepage.promotions.items.promo4.text',
          },
          {
            image: 'spins.svg',
            title: 'homepage.promotions.items.promo5.title',
            text: 'homepage.promotions.items.promo5.text',
          },
        ],
      ],
    };
  },
  computed: {
    localizedPromotions() {
      if (this.$i18n.locale === 'dk') {
        return [
          [
            {
              image: 'welcome.svg',
              title: 'homepage.promotions.items.promo1.title',
              text: 'homepage.promotions.items.promo1.text',
            },
            {
              image: 'vip.svg',
              title: 'homepage.promotions.items.promo3.title',
              text: 'homepage.promotions.items.promo3.text',
            },
          ],
          [
            {
              image: 'daily-picks.svg',
              title: 'homepage.promotions.items.promo4.title',
              text: 'homepage.promotions.items.promo4.text',
            },
          ],
        ];
      }
      return this.promotions;
    },
  },
};
</script>

<style lang="scss">
.Promotions {
  margin-bottom: 70px;

  &-Title {
    margin-bottom: 40px;
    text-align: center;

    @media(min-width: $screen-m) {
      text-align: left;
    }
  }

  &-Slider {
    position: relative;

    .v_slider__prev, .v_slider__next {
      top: 40%;

      @media (min-width: $screen-xs) {
        top: 45%;
      }
    }
  }
}
</style>
